//

@use "sass:math";

$spinner-width: 3rem !default;

$spinner-color: $primary !default;
$spinner-font-size: 0.875rem !default;
$spinner-speed: 800ms !default;
$spinner-width-xs: 1.5rem !default;

$spinner-width-sm: 2.2rem !default;

$spinner-width-sm: 18px !default;
$spinner-width-md: 24px !default;
$spinner-width-lg: 32px !default;
$spinner-width-xl: 48px !default;

$spinner-border-width-sm: 2px !default;
$spinner-border-width-md: 2.5px !default;
$spinner-border-width-lg: 3px !default;
$spinner-border-width-xl: 4px !default;

// Blinkers

$blinker-rectangle-ratio: math.div(6, 50);

$blinker-width-sm: 16px !default;
$blinker-width-md: 24px !default;
$blinker-width-lg: 32px !default;
$blinker-width-xl: 48px !default;

@mixin rectangle-ratio($size) {
  height: $size * $blinker-rectangle-ratio;
}

.blinker {
  @extends .spinner-grow;
  width: $blinker-width-md !important;
  height: $blinker-width-md !important;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
  animation: none;

  &::after {
    @include rectangle-ratio($blinker-width-md);
    content: '';
    display: block;
    background: $spinner-color;
    animation: blink $spinner-speed infinite;
  }
}

@keyframes blink {
  0% {
    background: $spinner-color;
  }
  40% {
    background: transparent;
  }
  100% {
    background: $spinner-color;
  }
}

.blinker-sm {
  width: $blinker-width-sm !important;
  height: $blinker-width-sm !important;
  &::after {
    @include rectangle-ratio($blinker-width-sm);
  }
}
.blinker-md {
  width: $blinker-width-md !important;
  height: $blinker-width-md !important;
  &::after {
    @include rectangle-ratio($blinker-width-md);
  }
}
.blinker-lg {
  width: $blinker-width-lg !important;
  height: $blinker-width-lg !important;
  &::after {
    @include rectangle-ratio($blinker-width-lg);
  }
}
.blinker-xl {
  width: $blinker-width-xl !important;
  height: $blinker-width-xl !important;
  &::after {
    @include rectangle-ratio($blinker-width-xl);
  }
}

//////////////////////////////////////////////////////////////////////

//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

%spinner {
  content: '';
  display: inline-block;
  width: $spinner-width-md;
  height: $spinner-width-md;
  vertical-align: text-bottom;
  border: $spinner-border-width-md solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner {
  @extend %spinner;
}

.spinner-sm {
  width: $spinner-width-sm;
  height: $spinner-width-sm;
  border-width: $spinner-border-width-sm;
}
.spinner-md {
  width: $spinner-width-md;
  height: $spinner-width-md;
  border-width: $spinner-border-width-md;
}
.spinner-lg {
  width: $spinner-width-lg;
  height: $spinner-width-lg;
  border-width: $spinner-border-width-lg;
}
.spinner-xl {
  width: $spinner-width-xl;
  height: $spinner-width-xl;
  border-width: $spinner-border-width-xl;
}

.btn.loading {
  position: relative;
  pointer-events: none;

  .loader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-size;
      height: $btn-icon-size;
      vertical-align: text-bottom;
      border: 2px solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      animation: spinner-border 0.75s linear infinite;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn.btn-#{$color}.loading {
    .loader {
      background-color: $value;
    }
  }
  .btn.btn-outline-#{$color}.loading {
    .loader {
      background-color: $value;

      &::before {
        @if $color == 'primary-alt' {
          color: $primary;
        } @else {
          color: $primary-alt;
        }
      }
    }

    &.btn-icon-start,
    &.btn-icon-end {
      .loader {
        &::before {
          color: currentColor;
        }
      }
    }
  }
  .btn.btn-flat-#{$color}.loading {
    .loader {
      background-color: $value;

      &::before {
        color: $primary-alt;
      }
    }

    &.btn-icon-start,
    &.btn-icon-end {
      .loader {
        &::before {
          color: currentColor;
        }
      }
    }
  }
  .btn.btn-discreet-#{$color}.loading {
    .loader {
      background-color: currentColor;

      &::before {
        color: $primary-alt;
      }
    }

    &.btn-icon-start,
    &.btn-icon-end {
      .loader {
        &::before {
          color: currentColor;
        }
      }
    }
  }
}

.btn.btn-icon-start.loading,
.btn.btn-icon-end.loading {
  .icon {
    opacity: 0;
  }
}

.btn.btn-icon-start.loading .loader,
.btn.btn-icon-end.loading .loader {
  background: transparent;
}

.btn.btn-icon-start.loading .loader {
  width: 28px;
}
.btn.btn-icon-end.loading .loader {
  width: 28px;
  right: 0;
  left: auto;
}

.btn.btn-icon-start.loading .loader,
.btn.btn-icon-end.loading .loader {
  width: 32px;
  &::before {
    width: $btn-icon-size;
    height: $btn-icon-size;
  }
}

.btn-sm {
  &.btn.btn-icon-start.loading .loader,
  &.btn.btn-icon-end.loading .loader {
    width: 20px;
    &::before {
      width: 14px;
      height: 14px;
    }
  }
}
.btn-md {
  &.btn.btn-icon-start.loading .loader,
  &.btn.btn-icon-end.loading .loader {
    width: 32px;
    &::before {
      width: $btn-icon-size;
      height: $btn-icon-size;
    }
  }
}
.btn-lg {
  &.btn.btn-icon-start.loading .loader,
  &.btn.btn-icon-end.loading .loader {
    width: 40px;
    &::before {
      width: $btn-icon-size-lg;
      height: $btn-icon-size-lg;
    }
  }
}
.btn-xl {
  &.btn.btn-icon-start.loading .loader,
  &.btn.btn-icon-end.loading .loader {
    width: 48px;
    &::before {
      width: $btn-icon-size-xl;
      height: $btn-icon-size-xl;
    }
  }
}
