@import 'bootstrap/scss/breadcrumb';

.breadcrumb-item {
  padding: var(--#{$prefix}breadcrumb-padding-y) var(--#{$prefix}breadcrumb-padding-x);
  a {
    color: $link-color;
    font-weight: $font-weight-medium;
  
    &:hover {
      color: $link-hover-color;
    }
  }
}
