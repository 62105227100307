@import 'bootstrap/scss/root';

:root {
  @each $color, $value in $secondary-colors {
    --#{$color}: #{$value};
  }
}

:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $custom-bg-colors {
    --bg-#{$color}: #{$value};
  }

  @each $color, $value in $bg-alt-colors {
    --bg-alt-#{$color}: #{$value};
  }
}
