$switch-square-color: $secondary !default;
$switch-active-color: $primary !default;
$switch-colored-color: $info !default;
$switch-indicator-border-radius: 0 !default;
$switch-indicator-size: 10px !default;
$switch-border-width: 2px !default;
$switch-square-size: 10px !default;
$switch-padding: 4px !default;
$switch-width: 40px !default;
$switch-height: 20px !default;
$switch-translation-time: 150ms !default;
$switch-fade-time: 90ms !default;
$switch-colorized-active-opacity: map-get($opacities, '70') !default;
$switch-easing: cubic-bezier(0.87, -0.41, 0.19, 1.44) !default;
$switch-colored: $info !default;

.form-switch {
  padding: 0;

  &.form-switch-right {
    display: inline-flex;
    justify-content: flex-end;

    .form-check-label {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &.form-switch-colored {
    .form-check-input:checked {
      border-color: $switch-colored-color;

      &::before {
        background-color: $switch-colored-color;
      }
    }
  }

  .form-check-input {
    margin: 0;
    position: relative;
    border-radius: 0;
    height: $switch-height;
    border: $switch-border-width solid $border-color;
    background: transparent !important;
    cursor: pointer;

    &:focus {
      box-shadow: none;
      border-color: $switch-active-color;
    }

    &::before {
      content: '';
      width: $switch-square-size;
      height: $switch-square-size;
      position: absolute;
      background-color: $switch-square-color;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0 auto $switch-padding;
      transition: transform 90ms;
    }

    &:checked {
      border-color: $switch-active-color;
      &::before {
        background-color: $switch-active-color;
        transform: translate(18px);
      }
    }
  }

  .form-check-label {
    margin-left: 8px;
    white-space: nowrap;
  }
}
