@mixin set-color-opacity-rules($colors) {
  @each $opacity in map-keys($opacities) {
    @each $color_name, $color_value in $colors {
      .bg-#{$color_name}-alpha-#{$opacity} {
        background: rgba(
          $color_value,
          map-get($opacities, $opacity)
        ) !important;
      }

      .alpha-#{$opacity} {
        opacity: map-get($opacities, $opacity) !important;
      }

      .text-#{$color_name}-alpha-#{$opacity} {
        color: rgba($color_value, map-get($opacities, $opacity)) !important;
      }
    }
  }
}

@include set-color-opacity-rules($theme-colors);
@include set-color-opacity-rules($custom-bg-colors);
@include set-color-opacity-rules($bg-alt-colors);
@include set-color-opacity-rules($secondary-colors);
