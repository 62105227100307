// Figures
.text-figures {
  line-height: 1 !important;
  font-weight: $font-weight-medium !important;
}

.monospace-numbers,
.tabular-figures {
  font-feature-settings: 'cv01', 'cv10', 'tnum' !important;
  letter-spacing: -0.07em !important;
  line-height: 1 !important;
  font-weight: $font-weight-medium !important;
}

// FONT WEIGTH
.fw-extrabold {
  font-weight: $font-weight-extrabold;
}
.fw-medium {
  font-weight: $font-weight-medium;
}

// DECORATIONS
.text-underline {
  @extend .text-decoration-underline;
}
.text-strikethrough {
  @extend .text-decoration-line-through;
}

// Secondary color palette utility
@each $color, $value in $secondary-colors {
  .text-#{$color} {
    color: $value !important;
  }
}

// adaptive colors on `.text-*`
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($adaptive-palette, $background),
      $name
    );
    .#{$background} .text-#{$name} {
      color: $adapted_color !important;
    }
  }
}

// adaptive exceptions on light backgrounds
@each $background in $adaptive-backgrounds-light {
  .#{$background} {
    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: text-color-light-background($background, 'success') !important;
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: text-color-light-background($background, 'warning') !important;
    }

    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($adaptive-palette, $background),
        'danger'
      ) !important;
    }
  }
}

// adaptive excceptions on dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  .#{$background} {
    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: danger-text-color-dark-background($background) !important;
    }

    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($adaptive-palette, $background),
        'success'
      ) !important;
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($adaptive-palette, $background),
        'warning'
      ) !important;
    }
  }
}
