@import 'bootstrap/scss/forms/form-check';

$check-height-sm: 16px;
$check-height-md: 18px;
$check-height-lg: 20px;
$check-height-xl: 24px;

$check-font-size-sm: 12px;
$check-font-size-md: 14px;
$check-font-size-lg: 14px;
$check-font-size-xl: 16px;

$check-spacing-sm: 2px;
$check-spacing-md: 7px;
$check-spacing-lg: 10px;
$check-spacing-xl: 12px;

$states: (
  valid: $form-feedback-valid-color,
  invalid: $form-feedback-invalid-color,
  edited: $form-feedback-edited-color,
  warning: $form-feedback-warning-color,
);

.form-check:not(.form-switch):not(.form-check-inline) {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;

  &:hover {
    .form-check-input {
      border-color: $primary;
    }

    .form-check-label {
      background-color: rgba($primary, 6%);
    }
  }

  .form-check-label {
    cursor: pointer;
    padding: $check-spacing-md $check-spacing-md $check-spacing-md (2*$check-spacing-md + $check-height-md);
    line-height: $check-height-md;
    font-size: $check-font-size-md;
    z-index: 0;
    color: $primary !important;

    &:empty {
      padding-left: $check-spacing-md !important;
      &::before {
        content: "";
        display: block;
        height: $check-height-md;
        width: $check-height-md;
      }
    }

    &:active {
      background-color: rgba($primary, 14%);
    }
  }

  .form-check-input {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 0;
    background: transparent;
    border: 2px solid $secondary;
    z-index: 1;

    height: $check-height-md !important;
    width: $check-height-md !important;
    left: $check-spacing-md;

    &:checked {
      background-color: $primary;
      border-color: $primary;
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M0 0h24v24H0z'/><path fill='#{$primary-alt}' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/></svg>")
      );
      background-position: center;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none !important;
    }

    &:focus-visible {
      & + .form-check-label {
        outline: 2px solid $secondary;
        background-color: rgba($primary, 6%);
      }
    }

    &:focus-visible:checked {
      & + .form-check-label {
        outline: 2px solid $primary;
      }
    }

    &[type='radio'] {
      background-color: $primary-alt;
      &:checked {
        background-image: escape-svg(
          url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z' fill='black'/><path d='M14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9Z' fill='#{$primary}'/></svg>")
        );
      }
      border-radius: 50%;
      & + .form-check-label {
        border-radius: 9999px;
      }
    }
  }

  // SIZING
  &.form-check-sm {
    .form-check-label {
      font-size: $check-font-size-sm !important;
      padding: $check-spacing-sm $check-spacing-sm $check-spacing-sm (2*$check-spacing-sm + $check-height-sm) !important;
      line-height: $check-height-sm;

      &:empty {
        padding-left: $check-spacing-sm !important;
        &::before {
          height: $check-height-sm;
          width: $check-height-sm;
        }
      }
    }
    .form-check-input {
      height: $check-height-sm !important;
      width: $check-height-sm !important;
      left: $check-spacing-sm;

      &[type='radio'] {
        &:checked {
          background-image: escape-svg(
            url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z' fill='black'/><path d='M12.25 8C12.25 10.3472 10.3472 12.25 8 12.25C5.65279 12.25 3.75 10.3472 3.75 8C3.75 5.65279 5.65279 3.75 8 3.75C10.3472 3.75 12.25 5.65279 12.25 8Z' fill='#{$primary}'/></svg>")
          ) !important;
        }
      }
    }
  }
  &.form-check-lg {
    .form-check-label {
      font-size: $check-font-size-lg;
      line-height: $check-height-lg;
      padding: $check-spacing-lg $check-spacing-lg $check-spacing-lg (2*$check-spacing-lg + $check-height-lg) !important;

      &:empty {
        padding-left: $check-spacing-lg !important;
        &::before {
          height: $check-height-lg;
          width: $check-height-lg;
        }
      }
    }
    .form-check-input {
      height: $check-height-lg !important;
      width: $check-height-lg !important;
      left: $check-spacing-lg;

      &[type='radio'] {
        &:checked {
          background-image: escape-svg(
            url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z' fill='black'/><path d='M15.5 10C15.5 13.0376 13.0376 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10Z' fill='#{$primary}'/></svg>")
          ) !important;
        }
      }
    }
  }
  &.form-check-xl {
    .form-check-label {
      font-size: $check-font-size-xl !important;
      line-height: $check-height-xl;
      padding: $check-spacing-xl $check-spacing-xl $check-spacing-xl (2*$check-spacing-xl + $check-height-xl) !important;

      &:empty {
        padding-left: $check-spacing-xl !important;
        &::before {
          height: $check-height-xl;
          width: $check-height-xl;
        }
      }
    }
    .form-check-input {
      height: $check-height-xl !important;
      width: $check-height-xl !important;
      left: $check-spacing-xl;

      &[type='radio'] {
        &:checked {
          background-image: escape-svg(
            url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' fill='#{$primary}'/><path d='M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z' fill='#{$primary}'/></svg>")
          ) !important;
        }
      }
    }
  }
}

// STATES
@mixin checkbox-color($color) {
  &:active + .form-check-label {
    background-color: rgba($color, 14%) !important;
  }

  border: 2px solid $color !important;
  &:checked {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='#{$color}'/><path fill='#{$primary-alt}' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/></svg>")
    ) !important;
    background-color: $color !important;
    border-color: $color !important;
  }

  &[type='radio'] {
    background-color: transparent !important;
    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' fill='#{$primary-alt}'/><path d='M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z' fill='#{$color}'/></svg>")
      ) !important;
    }
  }

  &:focus-visible {
    & + .form-check-label {
      outline: 2px solid $color;
      background-color: rgba($color, 6%) !important;
    }
  }

  &:focus-visible:checked {
    & + .form-check-label {
      outline: 2px solid $color;
    }
  }
}

@mixin checkbox-state($state, $color) {
  &.form-check:not(.form-switch) {
    &:hover {
      .form-check-input {
        border-color: $color !important;
      }
      .form-check-label {
        background-color: rgba($color, 6%) !important;
      }
    }

    .form-check-input {
      @include checkbox-color($color);
    }
  }
}

@each $state, $color in $states {
  .form-check-#{$state} {
    @include checkbox-state($state, $color);
  }
}

.form-check-input[disabled], .form-check-input:disabled {
  border-color: initial !important;
  ~ .form-check-label {
    cursor: default;
    background-color: transparent !important;
  }
}

// VALIDATION
.was-validated {
  .form-check:not(.form-switch):not(.form-check-inline):valid,
  .form-check-input:valid {
    &:hover {
      .form-check-input {
        border-color: map-get($states, "valid") !important;
      }
      & ~ .form-check-label {
        background-color: rgba(map-get($states, "valid"), 6%) !important;
      }
    }
    @include checkbox-color(map-get($states, "valid"));
  }

  .form-check:not(.form-switch):not(.form-check-inline):invalid,
  .form-check-input:invalid {
    &:hover {
      .form-check-input {
        border-color: map-get($states, "invalid") !important;
      }
      & ~ .form-check-label {
        background-color: rgba(map-get($states, "invalid"), 6%) !important;
      }
    }
    @include checkbox-color(map-get($states, "invalid"));
  }
}

.form-check:not(.form-switch):not(.form-check-inline).is-valid,
.form-check-input.is-valid {
  &:hover {
    .form-check-input {
      border-color: map-get($states, "valid") !important;
    }
    & ~ .form-check-label {
      background-color: rgba(map-get($states, "valid"), 6%) !important;
    }
  }
  @include checkbox-color(map-get($states, "valid"));
}

.form-check:not(.form-switch):not(.form-check-inline).is-invalid,
.form-check-input.is-invalid {
  &:hover {
    .form-check-input {
      border-color: map-get($states, "invalid") !important;
    }
    & ~ .form-check-label {
      background-color: rgba(map-get($states, "invalid"), 6%) !important;
    }
  }
  @include checkbox-color(map-get($states, "invalid"));
}

.form-check:not(.form-switch):not(.form-check-inline).is-warning,
.form-check-input.is-warning {
  &:hover {
    .form-check-input {
      border-color: map-get($states, "warning") !important;
    }
    & ~ .form-check-label {
      background-color: rgba(map-get($states, "warning"), 6%) !important;
    }
  }
  @include checkbox-color(map-get($states, "warning"));
}

.form-check:not(.form-switch):not(.form-check-inline).is-edited,
.form-check-input.is-edited {
  &:hover {
    .form-check-input {
      border-color: map-get($states, "edited") !important;
    }
    & ~ .form-check-label {
      background-color: rgba(map-get($states, "edited"), 6%) !important;
    }
  }
  @include checkbox-color(map-get($states, "edited"));
}
