$colors: map-merge($theme-colors, $secondary-colors);

@each $color, $value in $colors {
  .btn.btn-discreet-#{$color},
  .btn.btn-outline-#{$color},
  .btn.btn-#{$color} {
    &:focus-visible {
      outline: 2px solid $primary-alt !important;
      outline-offset: -4px !important;
      box-shadow: none !important;
    }
  }
}

a,
button.close,
.text-link,
.nav-link,
.list-group-item-action,
.btn {
  --#{$prefix}btn-focus-ring-color: #{$input-btn-focus-color};
  box-shadow: none;
  &:focus-visible {
    outline: none;
    border-radius: 0 !important;
    box-shadow: inset 0 0 0 0.125rem var(--#{$prefix}btn-focus-ring-color) !important;
  }
}

.form-select,
.form-control {
  &:focus-visible {
    --#{$prefix}btn-focus-ring-color: #{$input-btn-focus-color};
    border-radius: 0 !important;
    outline: none;
    box-shadow: inset 0 0 0 0.125rem var(--#{$prefix}btn-focus-ring-color) !important;
  }
}
