$utility-border-width: 1px !default;

$borders: (
  xl: 0.375rem,
  lg: 0.1875rem,
  md: 0.0625rem,
  sm: 0.03125rem,
) !default;

.border {
  --#{$prefix}border-color: #{$border-color} !important;
  --#{$prefix}border-style: solid !important;
  --#{$prefix}border-width: #{$border-width};
}

$border-orientations: (
  'top': 'top',
  'end': 'right',
  'bottom': 'bottom',
  'start': 'left',
);
@each $name, $direction in $border-orientations {
  .border-#{$name} {
    --#{$prefix}border-style: solid !important;
    --#{$prefix}border-width: #{$border-width};
    --#{$prefix}border-color: #{$border-color} !important;
  }
}

$border-sizes: 'sm' 'md' 'lg' 'xl';
@each $borderSize in $border-sizes {
  .border-#{$borderSize} {
    --#{$prefix}border-width: #{map-get($borders, $borderSize)} !important;
  }
}

// BORDER COLORS

@each $name, $color in $theme-colors {
  .border-#{$name} {
    --#{$prefix}border-color: #{$color} !important;
  }
}

// Secondary palette
@each $name, $color in $secondary-colors {
  .border-#{$name} {
    --#{$prefix}border-color: #{$color} !important;
  }
}

// BORDER OPACITY

@mixin set-border-opacity-rules($colors) {
  @each $opacity in map-keys($opacities) {
    .border.border-alpha-#{$opacity} {
      --#{$prefix}border-color: #{rgba(
          $border-color,
          map-get($opacities, $opacity)
        )} !important;
    }

    @each $color_name, $color_value in $colors {
      .border-#{$color_name}-alpha-#{$opacity} {
        --#{$prefix}border-color: #{rgba(
            $color_value,
            map-get($opacities, $opacity)
          )} !important;
      }

      .border-#{$color_name}.border-alpha-#{$opacity} {
        --#{$prefix}border-color: #{rgba(
            $color_value,
            map-get($opacities, $opacity)
          )} !important;
      }
    }
  }
}

@include set-border-opacity-rules($theme-colors);
@include set-border-opacity-rules($custom-bg-colors);
@include set-border-opacity-rules($bg-alt-colors);
@include set-border-opacity-rules($secondary-colors);
