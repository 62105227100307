@import 'bootstrap/scss/nav';

$nav-pills-active-border-width: 3px !default;

$nav-link-weight: $font-weight-medium !default;
$nav-link-color: $secondary !default;
$nav-link-hover-color: $primary !default;
$nav-link-active-color: $primary !default;
$nav-link-active-font-weight: $font-weight-semibold !default;
$nav-link-hover-focus-opacity: get-interaction-opacity("hover") !default;
$nav-link-active-opacity: get-interaction-opacity("selected") !default;
$nav-pills-opacity: map-get($opacities, '10') !default;

.nav-link {
  line-height: 1.5rem;
}

.nav-link.active:not(.disabled) {
  --#{$prefix}nav-link-color: #{$primary};
  --#{$prefix}nav-link-font-weight: #{$font-weight-semibold};
}

.nav-tabs {
  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        --#{$prefix}nav-tabs-link-active-border-color: #{$value};
        border-bottom-color: transparent !important;
        // border-color: $value $value $nav-tabs-link-active-bg;
      }
    }
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    // Separate y padding so the border is only highlighting the text
    padding: $nav-link-padding-y $nav-link-padding-x
      calc(#{$nav-link-padding-y} - #{$nav-pills-active-border-width});

    border-bottom: ($nav-pills-active-border-width - 1px) solid transparent;
  }

  .nav-link.active,
  .show > .nav-link {
    border-bottom: $nav-pills-active-border-width solid $primary;
  }
}

//
// Compact pills
//

.nav-compact-pills {
  .nav-link {
    // Separate y padding so the border is only highlighting the text
    padding: $nav-link-padding-y $nav-link-padding-x
      calc(#{$nav-link-padding-y} - #{$nav-pills-active-border-width});

    &.active {
      // The bottom border should only highlight the text
      padding-left: 0;
      padding-right: 0;
      // We are going to use :before/:after to fill the remaining space
      margin: 0 $nav-link-padding-x;
    }

    border-bottom: ($nav-pills-active-border-width - 1px) solid transparent;
  }

  .nav-link.active,
  .show > .nav-link {
    border-bottom: $nav-pills-active-border-width solid $primary;
    // Put the bottom border on the parent .nav border
    margin-bottom: -1px;
  }
}

//
// Vertical pills
//

.nav-vertical-pills {
  flex-direction: column;

  .nav-link {
    // Ensure to use a standard y spacing
    padding-left: map-get($spacers, 4);
  }

  // Nested vertical navs
  .nav-vertical-pills {
    border-left: 1px solid $border-color;
    margin: map-get($spacers, 2) 0 map-get($spacers, 2) map-get($spacers, 4);

    .nav-link.active {
      background-color: transparent !important;
      border-left: none;
      color: map-get($theme-colors, 'info');
      font-weight: $font-weight-semibold;
      padding-left: calc(#{map-get($spacers, 4)} - 3px);
    }
  }
}

//
// Nav hover
//

.nav-hover {
  .nav-link {
    &:hover, &:focus, &.focus {
      background-color: get-interaction-color("hover");
    }

    &:not([disabled]):not(.disabled):active {
      background-color: get-interaction-color("onclick");
    }

    &:not([disabled]):not(.disabled).active {
      background-color: get-interaction-color("selected");
    }
    // Nav pills have some missing space at right and left due to the bottom
    // border on the `.nav-link` being only under the text.
    // see above `.nav-compact-pills .nav-link.active` rule.
    &:not([disabled]):not(.disabled).active {
      @at-root {
        // Pills edge case
        //
        .nav-compact-pills#{&} {
          position: relative;

          &::before,
          &::after {
            content: '';
            width: 1rem;
            position: absolute;
            top: 0;
            bottom: -$nav-pills-active-border-width;
            background-color: get-interaction-color("selected");
          }

          &::before {
            left: -1rem;
          }

          &::after {
            right: -1rem;
          }
        }
      }
    }
  }
}

