
@each $color, $value in $theme-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $value in $secondary-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $value in $custom-bg-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $value in $bg-alt-colors {
  @include bg-variant('.bg-alt-#{$color}', $value);
}

// Adapted backgrounds
//
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} .bg-#{$name} {
      background-color: $color !important;
    }
  }
}
