@import 'bootstrap/scss/toasts';

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    .toast-header {
      color: $value;
    }
  }
}

.toast-header {
  &::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    background-color: currentColor;
  }

  .btn-close {
    padding: 0 !important;
    margin-right: 0;
    height: px-to-rem(14);
    width: px-to-rem(14);
  }
}

.toast-footer {
  border-top: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  display: flex;
  align-items: center;

  .btn {
    flex: 1;
    border: none;

    & + .btn {
      border-left: 1px solid var(--bs-toast-header-border-color);
    }
  }
}

.notification {
  @extend .toast;
}

.notification-header {
  @extend .toast-header;
}

.notification-body {
  @extend .toast-body;
}

.notification-footer {
  @extend .toast-footer;
}
