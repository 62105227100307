// Accordion only
.card-header [data-bs-toggle='collapse'] {
  // Turn arrow to the bottom when accordion level is open
  &:not(.collapsed) .icon {
    transform: rotate(90deg);
  }

  &.btn {
    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }
}

// SEPARATORS
// Component introduced by SG UX/UI team
// cf https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap-v4/issues/51#issuecomment-157287
.collapse-separator {
  .card {
    background: transparent;
  }

  .card-header [data-bs-toggle='collapse'] {
    position: relative;
    border-top: 1px solid $border-color;

    &.collapsed span {
      color: $secondary;
    }

    &:not(.collapsed) .icon {
      transform: rotate(180deg);
      transform-origin: 50% 46%;
    }

    span {
      z-index: 1;
      padding: 0.5rem;
      color: $primary;
      white-space: nowrap;
    }
  }

  &.collapse-separator-centered {
    .card-header [data-bs-toggle='collapse'] {
      border-top: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after,
      &::before {
        content: '';
        border-top: 1px solid $border-color;
        height: 2px;
        width: 100%;
        display: block;
      }
    }

    span {
      padding: 0.5rem;
      color: $primary;
    }
  }
}

// HTML details summary solution
details {
  summary {
    .btn:hover {
      background: transparent !important;
    }

    &::before {
      content: 'keyboard_arrow_right';
      font-family: 'Material Icons';
      font-size: 1.125rem;
      line-height: 1;
      margin-right: 0.5rem;
      color: $secondary;
    }
  }

  &[open] {
    summary {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}
