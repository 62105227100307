.display-5.text-success,
.h4.text-success,
h4.text-success,
.h5.text-success,
h5.text-succes {
  color: text-color-light-background('bg-lvl1', 'success') !important;
}

.display-5.text-warning,
.h4.text-warning,
h4.text-warning,
.h5.text-warning,
h5.text-warning {
  color: text-color-light-background('bg-lvl1', 'warning') !important;
}
